var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayShow
    ? _c(
        "div",
        [
          _c("div", [
            _c("h1", [_vm._v(_vm._s(_vm.$t("MANAGEMENT.EDIT_MEMBER")))]),
            _c("div", { staticClass: "section-group" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-8" }, [
                  _c(
                    "form",
                    {
                      attrs: { lass: "forms-sample", id: "form-agent" },
                      on: {
                        submit: function($event) {
                          return _vm.sendForm($event)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "mb-4" }, [
                        _c("div", { staticStyle: { display: "inline-flex" } }, [
                          _c("h4", { staticClass: "bold" }, [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.MEMBER_INFO")))
                          ])
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Username" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.USERNAME")) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "required" }, [
                                    _vm._v("★")
                                  ])
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "Username",
                                    name: "Username",
                                    placeholder: _vm.$t("MANAGEMENT.USERNAME"),
                                    disabled: "true",
                                    required: ""
                                  },
                                  domProps: { value: _vm.dataMember.username }
                                }),
                                _c(
                                  "small",
                                  { staticClass: "text-muted form-text" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("MANAGEMENT.TEXT_NUMBER_ONLY")
                                      ) + " "
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Name" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.NAME")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.dataMember.name,
                                      expression: "dataMember.name"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "Name",
                                    name: "Name",
                                    placeholder: _vm.$t("MANAGEMENT.NAME")
                                  },
                                  domProps: { value: _vm.dataMember.name },
                                  on: {
                                    keypress: function($event) {
                                      return _vm.clsAlphaNoOnly($event)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.dataMember,
                                        "name",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _c(
                                  "small",
                                  { staticClass: "text-muted form-text" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("MANAGEMENT.TEXT_NUMBER_ONLY")
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]),
                        false
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "Password" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.PASSWORD")
                                          ) +
                                          " "
                                      ),
                                      _c("span", { staticClass: "required" }, [
                                        _vm._v("★")
                                      ])
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    (_vm.password.default
                                      ? "text"
                                      : "password") === "checkbox"
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.dataMember.password,
                                              expression: "dataMember.password"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "Password",
                                            name: "Password",
                                            placeholder: _vm.$t(
                                              "MANAGEMENT.PASSWORD"
                                            ),
                                            autocomplete: "new-password",
                                            type: "checkbox"
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.dataMember.password
                                            )
                                              ? _vm._i(
                                                  _vm.dataMember.password,
                                                  null
                                                ) > -1
                                              : _vm.dataMember.password
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.dataMember.password,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.dataMember,
                                                      "password",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.dataMember,
                                                      "password",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.dataMember,
                                                  "password",
                                                  $$c
                                                )
                                              }
                                            }
                                          }
                                        })
                                      : (_vm.password.default
                                          ? "text"
                                          : "password") === "radio"
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.dataMember.password,
                                              expression: "dataMember.password"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "Password",
                                            name: "Password",
                                            placeholder: _vm.$t(
                                              "MANAGEMENT.PASSWORD"
                                            ),
                                            autocomplete: "new-password",
                                            type: "radio"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.dataMember.password,
                                              null
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.dataMember,
                                                "password",
                                                null
                                              )
                                            }
                                          }
                                        })
                                      : _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.dataMember.password,
                                              expression: "dataMember.password"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "Password",
                                            name: "Password",
                                            placeholder: _vm.$t(
                                              "MANAGEMENT.PASSWORD"
                                            ),
                                            autocomplete: "new-password",
                                            type: _vm.password.default
                                              ? "text"
                                              : "password"
                                          },
                                          domProps: {
                                            value: _vm.dataMember.password
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.dataMember,
                                                "password",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                    _c(
                                      "small",
                                      { staticClass: "text-muted form-text" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.PASSWORD_CHECK")
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "toggle-password field-icon",
                                        attrs: { toggle: "#Password" }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-eye",
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleShowPassword(
                                                "default"
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "ConfirmPassword" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "MANAGEMENT.CONFIRM_PASSWORD"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c("span", { staticClass: "required" }, [
                                        _vm._v("★")
                                      ])
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    (_vm.password.confirm
                                      ? "text"
                                      : "password") === "checkbox"
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.dataMember.confirmPassword,
                                              expression:
                                                "dataMember.confirmPassword"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "ConfirmPassword",
                                            name: "ConfirmPassword",
                                            placeholder: _vm.$t(
                                              "MANAGEMENT.CONFIRM_PASSWORD"
                                            ),
                                            type: "checkbox"
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.dataMember.confirmPassword
                                            )
                                              ? _vm._i(
                                                  _vm.dataMember
                                                    .confirmPassword,
                                                  null
                                                ) > -1
                                              : _vm.dataMember.confirmPassword
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a =
                                                  _vm.dataMember
                                                    .confirmPassword,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.dataMember,
                                                      "confirmPassword",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.dataMember,
                                                      "confirmPassword",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.dataMember,
                                                  "confirmPassword",
                                                  $$c
                                                )
                                              }
                                            }
                                          }
                                        })
                                      : (_vm.password.confirm
                                          ? "text"
                                          : "password") === "radio"
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.dataMember.confirmPassword,
                                              expression:
                                                "dataMember.confirmPassword"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "ConfirmPassword",
                                            name: "ConfirmPassword",
                                            placeholder: _vm.$t(
                                              "MANAGEMENT.CONFIRM_PASSWORD"
                                            ),
                                            type: "radio"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.dataMember.confirmPassword,
                                              null
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.dataMember,
                                                "confirmPassword",
                                                null
                                              )
                                            }
                                          }
                                        })
                                      : _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.dataMember.confirmPassword,
                                              expression:
                                                "dataMember.confirmPassword"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "ConfirmPassword",
                                            name: "ConfirmPassword",
                                            placeholder: _vm.$t(
                                              "MANAGEMENT.CONFIRM_PASSWORD"
                                            ),
                                            type: _vm.password.confirm
                                              ? "text"
                                              : "password"
                                          },
                                          domProps: {
                                            value:
                                              _vm.dataMember.confirmPassword
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.dataMember,
                                                "confirmPassword",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "toggle-password field-icon",
                                        attrs: { toggle: "#ConfirmPassword" }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-eye",
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleShowPassword(
                                                "confirm"
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            ])
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "mb-4" }, [
                        _c("h4", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("MANAGEMENT.CONTACT_INFO")))
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Email" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.EMAIL")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.dataMember.email,
                                      expression: "dataMember.email"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "email",
                                    id: "Email",
                                    name: "Email",
                                    placeholder: _vm.$t("MANAGEMENT.EMAIL")
                                  },
                                  domProps: { value: _vm.dataMember.email },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.dataMember,
                                        "email",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Phone" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.PHONE")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.dataMember.phone,
                                      expression: "dataMember.phone"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "Phone",
                                    name: "Phone",
                                    placeholder: _vm.$t("MANAGEMENT.PHONE")
                                  },
                                  domProps: { value: _vm.dataMember.phone },
                                  on: {
                                    keydown: function($event) {
                                      return _vm.onlyNumber($event)
                                    },
                                    keypress: function($event) {
                                      return _vm.onlyNumber($event)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.dataMember,
                                        "phone",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _c(
                                  "small",
                                  { staticClass: "text-muted form-text" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("MANAGEMENT.NUMBER_ONLY"))
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "mb-4" }, [
                        _c("h4", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("MANAGEMENT.OTHER_INFO")))
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Referrer" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.REFERRER")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.dataMember.referrer,
                                      expression: "dataMember.referrer"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "Referrer",
                                    placeholder: _vm.$t("MANAGEMENT.REFERRER")
                                  },
                                  domProps: { value: _vm.dataMember.referrer },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.dataMember,
                                        "referrer",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Remark" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.REMARK")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.dataMember.remark,
                                      expression: "dataMember.remark"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "Remark",
                                    placeholder: _vm.$t("MANAGEMENT.REMARK")
                                  },
                                  domProps: { value: _vm.dataMember.remark },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.dataMember,
                                        "remark",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "mb-4" }, [
                        _c("h4", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("MANAGEMENT.STATUS")))
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Suspend" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("MANAGEMENT.BUTTON.SUSPEND")
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn-group btn-group-toggle d-flex",
                                    attrs: {
                                      role: "group",
                                      "aria-label": "Basic example"
                                    }
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        class: {
                                          active: _vm.dataMember.suspend == true
                                        },
                                        attrs: {
                                          id: "selectSuspend",
                                          type: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.dataMember.suspend = true
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("YES")) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        class: {
                                          active:
                                            _vm.dataMember.suspend == false
                                        },
                                        attrs: {
                                          id: "selectNoSuspend",
                                          type: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.dataMember.suspend = false
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(_vm.$t("NO")) + " ")]
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Lock" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.BUTTON.LOCK")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn-group btn-group-toggle d-flex",
                                    attrs: {
                                      role: "group",
                                      "aria-label": "Basic example"
                                    }
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        class: {
                                          active: _vm.dataMember.lock == true
                                        },
                                        attrs: {
                                          id: "selectLOCK",
                                          type: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.dataMember.lock = true
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("YES")) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        class: {
                                          active: _vm.dataMember.lock == false
                                        },
                                        attrs: {
                                          id: "selectNoLOCK",
                                          type: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.dataMember.lock = false
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(_vm.$t("NO")) + " ")]
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "mb-4" }, [
                            _c("h4", { staticClass: "bold" }, [
                              _vm._v(
                                _vm._s(_vm.$t("MANAGEMENT.SETTING_CREDIT"))
                              )
                            ]),
                            _vm._m(0)
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-12" }, [
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  { staticClass: "col-4 col-form-label bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("MANAGEMENT.CURRENCY")) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "required" }, [
                                      _vm._v("★")
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-8" },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        required: "",
                                        id: "currency",
                                        name: "currency",
                                        clearable: false,
                                        options: _vm.currencyArray,
                                        disabled:
                                          _vm.role == "Master Agent"
                                            ? true
                                            : false,
                                        placeholder: _vm.$t(
                                          "MANAGEMENT.CURRENCY"
                                        )
                                      },
                                      model: {
                                        value: _vm.dataMember.currency,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.dataMember,
                                            "currency",
                                            $$v
                                          )
                                        },
                                        expression: "dataMember.currency"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-12" }, [
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  { staticClass: "col-4 col-form-label bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("MANAGEMENT.CREDIT")) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "required" }, [
                                      _vm._v("★")
                                    ])
                                  ]
                                ),
                                _c("div", { staticClass: "col-8" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.dataMember.creditLimit,
                                        expression: "dataMember.creditLimit"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "creditLimit",
                                      name: "creditLimit",
                                      placeholder: _vm.$t("MANAGEMENT.CREDIT"),
                                      disabled: "true"
                                    },
                                    domProps: {
                                      value: _vm.dataMember.creditLimit
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.dataMember,
                                          "creditLimit",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "tab-section card col-md-12" }, [
                        _c(
                          "div",
                          { staticClass: "tab-control-panel card-header" },
                          [
                            _c(
                              "ul",
                              {
                                staticClass: "nav nav-tabs",
                                attrs: { role: "tablist" }
                              },
                              [
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link active",
                                      attrs: {
                                        href: "#tl-md1",
                                        id: "",
                                        "data-toggle": "tab",
                                        role: "tab",
                                        "aria-controls": "tl-tab-h1",
                                        "aria-selected": "true"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("GAME_TYPE.ALLIN_OR_FOLD")
                                        )
                                      )
                                    ]
                                  )
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        href: "#tl-md2",
                                        id: "",
                                        "data-toggle": "tab",
                                        role: "tab",
                                        "aria-controls": "ll-tab-md",
                                        "aria-selected": "false"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("GAME_TYPE.HOLDEM")))]
                                  )
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        href: "#tl-md3",
                                        id: "",
                                        "data-toggle": "tab",
                                        role: "tab",
                                        "aria-controls": "",
                                        "aria-selected": "false"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("GAME_TYPE.SIX_PLUS_HOLDEM")
                                        )
                                      )
                                    ]
                                  )
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        href: "#tl-md4",
                                        id: "",
                                        "data-toggle": "tab",
                                        role: "tab",
                                        "aria-controls": "",
                                        "aria-selected": "false"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("GAME_TYPE.PLO4")))]
                                  )
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        href: "#tl-md5",
                                        id: "",
                                        "data-toggle": "tab",
                                        role: "tab",
                                        "aria-controls": "",
                                        "aria-selected": "false"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("GAME_TYPE.PLO5")))]
                                  )
                                ])
                              ]
                            )
                          ]
                        ),
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "tab-content" }, [
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade show active",
                                attrs: {
                                  id: "tl-md1",
                                  role: "tabpanel",
                                  "aria-labelledby": ""
                                }
                              },
                              [
                                _c("div", { staticClass: "row mb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._m(1),
                                    _c("div", { staticClass: "row" }),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-4 col-form-label bold"
                                              },
                                              [_vm._v(" Status ")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataMember
                                                          .flodStatus,
                                                      expression:
                                                        "dataMember.flodStatus"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "statusflod1",
                                                    name: "statusflod",
                                                    value: "true"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataMember.flodStatus,
                                                      "true"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataMember,
                                                        "flodStatus",
                                                        "true"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "statusflod1"
                                                    }
                                                  },
                                                  [_vm._v(" Enable ")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataMember
                                                          .flodStatus,
                                                      expression:
                                                        "dataMember.flodStatus"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "statusflod2",
                                                    name: "statusflod",
                                                    value: "false"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataMember.flodStatus,
                                                      "false"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataMember,
                                                        "flodStatus",
                                                        "false"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "statusflod2"
                                                    }
                                                  },
                                                  [_vm._v(" Disable ")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.OUR_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                ),
                                                _vm._v(" : ")
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .allinorfold,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    ),
                                                    disabled: true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .flodOurPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "flodOurPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.flodOurPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.HOLD_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                ),
                                                _vm._v(" : ")
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .allinorfold,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    ),
                                                    disabled: true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .flodHoldPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "flodHoldPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.flodHoldPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-5" },
                                    [
                                      _c("div", { staticClass: "mb-4" }, [
                                        _c("h4", { staticClass: "bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" })
                                      ]),
                                      _vm._l(_vm.flod_limit_list, function(
                                        t,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          { key: index, staticClass: "row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-sm-12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group row"
                                                  },
                                                  [
                                                    _c("label", {
                                                      staticClass:
                                                        "col-1 col-form-label bold"
                                                    }),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-11" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.dataMember
                                                                  .flodLimitArr,
                                                              expression:
                                                                "dataMember.flodLimitArr"
                                                            }
                                                          ],
                                                          staticClass: "chk",
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              "allin_checkbox_" +
                                                              index,
                                                            disabled:
                                                              _vm
                                                                .parentRoyaltySetting
                                                                .allinorfold ==
                                                                undefined ||
                                                              _vm.parentRoyaltySetting.allinorfold.limit.indexOf(
                                                                t.tierValue
                                                              ) < 0
                                                          },
                                                          domProps: {
                                                            value: t.tierValue,
                                                            checked: Array.isArray(
                                                              _vm.dataMember
                                                                .flodLimitArr
                                                            )
                                                              ? _vm._i(
                                                                  _vm.dataMember
                                                                    .flodLimitArr,
                                                                  t.tierValue
                                                                ) > -1
                                                              : _vm.dataMember
                                                                  .flodLimitArr
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm.dataMember
                                                                    .flodLimitArr,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    t.tierValue,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.dataMember,
                                                                      "flodLimitArr",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.dataMember,
                                                                      "flodLimitArr",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.dataMember,
                                                                  "flodLimitArr",
                                                                  $$c
                                                                )
                                                              }
                                                            }
                                                          }
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label bold",
                                                            attrs: {
                                                              for:
                                                                "allin_checkbox_" +
                                                                index
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(t.name) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade",
                                attrs: {
                                  id: "tl-md2",
                                  role: "tabpanel",
                                  "aria-labelledby": ""
                                }
                              },
                              [
                                _c("div", { staticClass: "row mb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._m(2),
                                    _c("div", { staticClass: "row" }),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-4 col-form-label bold"
                                              },
                                              [_vm._v(" Status ")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataMember
                                                          .holdemStatus,
                                                      expression:
                                                        "dataMember.holdemStatus"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "statudholdem1",
                                                    name: "statusholdem",
                                                    value: "true"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataMember
                                                        .holdemStatus,
                                                      "true"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataMember,
                                                        "holdemStatus",
                                                        "true"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "statudholdem1"
                                                    }
                                                  },
                                                  [_vm._v(" Enable ")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataMember
                                                          .holdemStatus,
                                                      expression:
                                                        "dataMember.holdemStatus"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "statudholdem2",
                                                    name: "statusholdem",
                                                    value: "false"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataMember
                                                        .holdemStatus,
                                                      "false"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataMember,
                                                        "holdemStatus",
                                                        "false"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "statudholdem2"
                                                    }
                                                  },
                                                  [_vm._v(" Disable ")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.OUR_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .holdem,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    ),
                                                    disabled: true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .holdemOurPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "holdemOurPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.holdemOurPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.HOLD_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .holdem,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    ),
                                                    disabled: true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .holdemHoldPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "holdemHoldPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.holdemHoldPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-5" },
                                    [
                                      _c("div", { staticClass: "mb-4" }, [
                                        _c("h4", { staticClass: "bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" })
                                      ]),
                                      _vm._l(_vm.holdem_limit_list, function(
                                        t,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          { key: index, staticClass: "row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-sm-12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group row"
                                                  },
                                                  [
                                                    _c("label", {
                                                      staticClass:
                                                        "col-1 col-form-label bold"
                                                    }),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-11" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.dataMember
                                                                  .holdemLimitArr,
                                                              expression:
                                                                "dataMember.holdemLimitArr"
                                                            }
                                                          ],
                                                          staticClass: "chk",
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              "holdem_checkbox_" +
                                                              index,
                                                            disabled:
                                                              _vm
                                                                .parentRoyaltySetting
                                                                .holdem ==
                                                                undefined ||
                                                              _vm.parentRoyaltySetting.holdem.limit.indexOf(
                                                                t.tierValue
                                                              ) < 0
                                                          },
                                                          domProps: {
                                                            value: t.tierValue,
                                                            checked: Array.isArray(
                                                              _vm.dataMember
                                                                .holdemLimitArr
                                                            )
                                                              ? _vm._i(
                                                                  _vm.dataMember
                                                                    .holdemLimitArr,
                                                                  t.tierValue
                                                                ) > -1
                                                              : _vm.dataMember
                                                                  .holdemLimitArr
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm.dataMember
                                                                    .holdemLimitArr,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    t.tierValue,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.dataMember,
                                                                      "holdemLimitArr",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.dataMember,
                                                                      "holdemLimitArr",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.dataMember,
                                                                  "holdemLimitArr",
                                                                  $$c
                                                                )
                                                              }
                                                            }
                                                          }
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label bold",
                                                            attrs: {
                                                              for:
                                                                "holdem_checkbox_" +
                                                                index
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(t.name) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade",
                                attrs: {
                                  id: "tl-md3",
                                  role: "tabpanel",
                                  "aria-labelledby": ""
                                }
                              },
                              [
                                _c("div", { staticClass: "row mb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._m(3),
                                    _c("div", { staticClass: "row" }),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-4 col-form-label bold"
                                              },
                                              [_vm._v(" Status ")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataMember
                                                          .sixholdemStatus,
                                                      expression:
                                                        "dataMember.sixholdemStatus"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "sixholdemstatus1",
                                                    name: "sixholdemstatus",
                                                    value: "true"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataMember
                                                        .sixholdemStatus,
                                                      "true"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataMember,
                                                        "sixholdemStatus",
                                                        "true"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "sixholdemstatus1"
                                                    }
                                                  },
                                                  [_vm._v(" Enable ")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataMember
                                                          .sixholdemStatus,
                                                      expression:
                                                        "dataMember.sixholdemStatus"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "sixholdemstatus2",
                                                    name: "sixholdemstatus",
                                                    value: "false"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataMember
                                                        .sixholdemStatus,
                                                      "false"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataMember,
                                                        "sixholdemStatus",
                                                        "false"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "sixholdemstatus2"
                                                    }
                                                  },
                                                  [_vm._v(" Disable ")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.OUR_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .sixplus,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    ),
                                                    disabled: true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .sixholdemOurPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "sixholdemOurPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.sixholdemOurPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.HOLD_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .sixplus,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    ),
                                                    disabled: true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .sixholdemHoldPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "sixholdemHoldPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.sixholdemHoldPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-5" },
                                    [
                                      _c("div", { staticClass: "mb-4" }, [
                                        _c("h4", { staticClass: "bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" })
                                      ]),
                                      _vm._l(
                                        _vm.sixplus_holdemlimit_list,
                                        function(t, index) {
                                          return _c(
                                            "div",
                                            { key: index, staticClass: "row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-12" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group row"
                                                    },
                                                    [
                                                      _c("label", {
                                                        staticClass:
                                                          "col-1 col-form-label bold"
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-11"
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.dataMember
                                                                    .sixholdemLimitArr,
                                                                expression:
                                                                  "dataMember.sixholdemLimitArr"
                                                              }
                                                            ],
                                                            staticClass: "chk",
                                                            attrs: {
                                                              type: "checkbox",
                                                              id:
                                                                "sixholdem_checkbox_" +
                                                                index,
                                                              disabled:
                                                                _vm
                                                                  .parentRoyaltySetting
                                                                  .sixplus ==
                                                                  undefined ||
                                                                _vm.parentRoyaltySetting.sixplus.limit.indexOf(
                                                                  t.tierValue
                                                                ) < 0
                                                            },
                                                            domProps: {
                                                              value:
                                                                t.tierValue,
                                                              checked: Array.isArray(
                                                                _vm.dataMember
                                                                  .sixholdemLimitArr
                                                              )
                                                                ? _vm._i(
                                                                    _vm
                                                                      .dataMember
                                                                      .sixholdemLimitArr,
                                                                    t.tierValue
                                                                  ) > -1
                                                                : _vm.dataMember
                                                                    .sixholdemLimitArr
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm
                                                                      .dataMember
                                                                      .sixholdemLimitArr,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c = $$el.checked
                                                                    ? true
                                                                    : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      t.tierValue,
                                                                    $$i = _vm._i(
                                                                      $$a,
                                                                      $$v
                                                                    )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        _vm.dataMember,
                                                                        "sixholdemLimitArr",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        _vm.dataMember,
                                                                        "sixholdemLimitArr",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    _vm.dataMember,
                                                                    "sixholdemLimitArr",
                                                                    $$c
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          }),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form-check-label bold",
                                                              attrs: {
                                                                for:
                                                                  "sixholdem_checkbox_" +
                                                                  index
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    t.name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade",
                                attrs: {
                                  id: "tl-md4",
                                  role: "tabpanel",
                                  "aria-labelledby": ""
                                }
                              },
                              [
                                _c("div", { staticClass: "row mb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._m(4),
                                    _c("div", { staticClass: "row" }),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-4 col-form-label bold"
                                              },
                                              [_vm._v(" Status ")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataMember
                                                          .plo4Status,
                                                      expression:
                                                        "dataMember.plo4Status"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "plo4status1",
                                                    name: "plo4status",
                                                    value: "true"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataMember.plo4Status,
                                                      "true"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataMember,
                                                        "plo4Status",
                                                        "true"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "plo4status1"
                                                    }
                                                  },
                                                  [_vm._v(" Enable ")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataMember
                                                          .plo4Status,
                                                      expression:
                                                        "dataMember.plo4Status"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "plo4status2",
                                                    name: "plo4status",
                                                    value: "false"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataMember.plo4Status,
                                                      "false"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataMember,
                                                        "plo4Status",
                                                        "false"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "plo4status2"
                                                    }
                                                  },
                                                  [_vm._v(" Disable ")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.OUR_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .plo4,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    ),
                                                    disabled: true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .plo4OurPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "plo4OurPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.plo4OurPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.HOLD_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "",
                                                    name: "",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .plo4,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    ),
                                                    disabled: true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .plo4HoldPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "plo4HoldPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.plo4HoldPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-5" },
                                    [
                                      _c("div", { staticClass: "mb-4" }, [
                                        _c("h4", { staticClass: "bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" })
                                      ]),
                                      _vm._l(_vm.plo4_limit_list, function(
                                        t,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          { key: index, staticClass: "row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-sm-12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group row"
                                                  },
                                                  [
                                                    _c("label", {
                                                      staticClass:
                                                        "col-1 col-form-label bold"
                                                    }),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-11" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.dataMember
                                                                  .plo4LimitArr,
                                                              expression:
                                                                "dataMember.plo4LimitArr"
                                                            }
                                                          ],
                                                          staticClass: "chk",
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              "plo4_checkbox_" +
                                                              index,
                                                            disabled:
                                                              _vm
                                                                .parentRoyaltySetting
                                                                .plo4 ==
                                                                undefined ||
                                                              _vm.parentRoyaltySetting.plo4.limit.indexOf(
                                                                t.tierValue
                                                              ) < 0
                                                          },
                                                          domProps: {
                                                            value: t.tierValue,
                                                            checked: Array.isArray(
                                                              _vm.dataMember
                                                                .plo4LimitArr
                                                            )
                                                              ? _vm._i(
                                                                  _vm.dataMember
                                                                    .plo4LimitArr,
                                                                  t.tierValue
                                                                ) > -1
                                                              : _vm.dataMember
                                                                  .plo4LimitArr
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm.dataMember
                                                                    .plo4LimitArr,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    t.tierValue,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.dataMember,
                                                                      "plo4LimitArr",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.dataMember,
                                                                      "plo4LimitArr",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.dataMember,
                                                                  "plo4LimitArr",
                                                                  $$c
                                                                )
                                                              }
                                                            }
                                                          }
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label bold",
                                                            attrs: {
                                                              for:
                                                                "plo4_checkbox_" +
                                                                index
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(t.name) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade",
                                attrs: {
                                  id: "tl-md5",
                                  role: "tabpanel",
                                  "aria-labelledby": ""
                                }
                              },
                              [
                                _c("div", { staticClass: "row mb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._m(5),
                                    _c("div", { staticClass: "row" }),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-4 col-form-label bold"
                                              },
                                              [_vm._v(" Status ")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataMember
                                                          .plo5Status,
                                                      expression:
                                                        "dataMember.plo5Status"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "plo5status1",
                                                    name: "plo5status",
                                                    value: "true"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataMember.plo5Status,
                                                      "true"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataMember,
                                                        "plo5Status",
                                                        "true"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "plo5status1"
                                                    }
                                                  },
                                                  [_vm._v(" Enable ")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataMember
                                                          .plo5Status,
                                                      expression:
                                                        "dataMember.plo5Status"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "plo5status2",
                                                    name: "plo5status",
                                                    value: "false"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataMember.plo5Status,
                                                      "false"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataMember,
                                                        "plo5Status",
                                                        "false"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "plo5status2"
                                                    }
                                                  },
                                                  [_vm._v(" Disable ")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.OUR_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .plo5,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    ),
                                                    disabled: true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .plo5OurPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "plo5OurPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.plo5OurPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.HOLD_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .plo5,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    ),
                                                    disabled: true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .plo5HoldPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "plo5HoldPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.plo5HoldPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-5" },
                                    [
                                      _c("div", { staticClass: "mb-4" }, [
                                        _c("h4", { staticClass: "bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" })
                                      ]),
                                      _vm._l(_vm.plo5_limit_list, function(
                                        t,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          { key: index, staticClass: "row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-sm-12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group row"
                                                  },
                                                  [
                                                    _c("label", {
                                                      staticClass:
                                                        "col-1 col-form-label bold"
                                                    }),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-11" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.dataMember
                                                                  .plo5LimitArr,
                                                              expression:
                                                                "dataMember.plo5LimitArr"
                                                            }
                                                          ],
                                                          staticClass: "chk",
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              "plo5_checkbox_" +
                                                              index,
                                                            disabled:
                                                              _vm
                                                                .parentRoyaltySetting
                                                                .plo5 ==
                                                                undefined ||
                                                              _vm.parentRoyaltySetting.plo5.limit.indexOf(
                                                                t.tierValue
                                                              ) < 0
                                                          },
                                                          domProps: {
                                                            value: t.tierValue,
                                                            checked: Array.isArray(
                                                              _vm.dataMember
                                                                .plo5LimitArr
                                                            )
                                                              ? _vm._i(
                                                                  _vm.dataMember
                                                                    .plo5LimitArr,
                                                                  t.tierValue
                                                                ) > -1
                                                              : _vm.dataMember
                                                                  .plo5LimitArr
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm.dataMember
                                                                    .plo5LimitArr,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    t.tierValue,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.dataMember,
                                                                      "plo5LimitArr",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.dataMember,
                                                                      "plo5LimitArr",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.dataMember,
                                                                  "plo5LimitArr",
                                                                  $$c
                                                                )
                                                              }
                                                            }
                                                          }
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label bold",
                                                            attrs: {
                                                              for:
                                                                "plo5_checkbox_" +
                                                                index
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(t.name) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("div"),
                      _c(
                        "div",
                        {
                          staticClass: "mb-4",
                          staticStyle: { "text-align": "center" }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-yellow ripple-parent mr-2",
                              attrs: { type: "submit", id: "agent-submit" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("MEMBER.BUTTON.SAVE")) + " "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-md-4",
                    staticStyle: { "padding-left": "20px" }
                  },
                  [
                    _c("div", { staticClass: "mb-4" }, [
                      _c("h4", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.$t("MANAGEMENT.EDIT_HISTORY")))
                      ]),
                      _c(
                        "table",
                        { staticClass: "table table-bordered table-hover" },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("MANAGEMENT.DATE")))
                              ]),
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("MANAGEMENT.EDITED_BY")))
                              ])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.historyLists, function(list, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "link",
                                      on: {
                                        click: function($event) {
                                          return _vm.getHistoryItem(
                                            list.updatedDate
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("date")(list.updatedDate)
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]),
                                _c("td", [_vm._v(_vm._s(list.updatedBy))])
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ]),
          _c(
            "modal",
            {
              attrs: {
                name: "modalHistory",
                width: "800",
                height: "auto",
                clickToClose: false,
                adaptive: true
              }
            },
            [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: { click: _vm.hide }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "modal-body",
                  staticStyle: { height: "80vh", "overflow-y": "auto" }
                },
                [
                  _vm.historyLoading
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "mb-4" }, [
                            _c("h4", { staticClass: "bold" }, [
                              _vm._v(_vm._s(_vm.$t("MANAGEMENT.AGENT_INFO")))
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "Username" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.USERNAME")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "Username",
                                        name: "Username",
                                        disabled: ""
                                      },
                                      domProps: {
                                        value: _vm.historyData.username
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "Name" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("MANAGEMENT.NAME")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "Name",
                                        name: "Name",
                                        disabled: ""
                                      },
                                      domProps: { value: _vm.historyData.name }
                                    })
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "mb-4" }, [
                            _c("h4", { staticClass: "bold" }, [
                              _vm._v(_vm._s(_vm.$t("MANAGEMENT.CONTACT_INFO")))
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "Email" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("MANAGEMENT.EMAIL")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "Email",
                                        name: "Email",
                                        disabled: ""
                                      },
                                      domProps: { value: _vm.historyData.email }
                                    })
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "Phone" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("MANAGEMENT.PHONE")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "Phone",
                                        name: "Phone",
                                        disabled: ""
                                      },
                                      domProps: { value: _vm.historyData.phone }
                                    })
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "mb-4" }, [
                            _c("h4", { staticClass: "bold" }, [
                              _vm._v(_vm._s(_vm.$t("MANAGEMENT.OTHER_INFO")))
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "Referrer" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.REFERRER")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "Referrer",
                                        placeholder: _vm.$t(
                                          "MANAGEMENT.REFERRER"
                                        ),
                                        disabled: ""
                                      },
                                      domProps: {
                                        value: _vm.historyData.referrer
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "Remark" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("MANAGEMENT.REMARK")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "Remark",
                                        placeholder: _vm.$t(
                                          "MANAGEMENT.REMARK"
                                        ),
                                        disabled: ""
                                      },
                                      domProps: {
                                        value: _vm.historyData.remark
                                      }
                                    })
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "mb-4" }, [
                            _c("h4", { staticClass: "bold" }, [
                              _vm._v(_vm._s(_vm.$t("MANAGEMENT.STATUS")))
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "suspend" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.BUTTON.SUSPEND")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "suspend",
                                        placeholder: _vm.$t(
                                          "MANAGEMENT.BUTTON.SUSPEND"
                                        ),
                                        disabled: ""
                                      },
                                      domProps: {
                                        value: _vm.historyData.suspend
                                          ? _vm.$t("YES")
                                          : _vm.$t("NO")
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "lock" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.BUTTON.LOCK")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "lock",
                                        placeholder: _vm.$t(
                                          "MANAGEMENT.BUTTON.LOCK"
                                        ),
                                        disabled: ""
                                      },
                                      domProps: {
                                        value: _vm.historyData.lock
                                          ? _vm.$t("YES")
                                          : _vm.$t("NO")
                                      }
                                    })
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("div", { staticClass: "mb-4" }, [
                                _c("h4", { staticClass: "bold" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("MANAGEMENT.SETTING_CREDIT"))
                                  )
                                ]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-sm-12" }, [
                                    _c("div", { staticClass: "form-group row" })
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-4 col-form-label bold",
                                        attrs: { for: "Currency" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.CURRENCY")
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-8" }, [
                                      _c("input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "Currency",
                                          name: "Currency",
                                          disabled: ""
                                        },
                                        domProps: {
                                          value: _vm.historyData.currency
                                        }
                                      })
                                    ])
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-4 col-form-label bold",
                                        attrs: { for: "Creditlimit" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.CREDIT")
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-8" }, [
                                      _c("input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "Creditlimit",
                                          name: "Creditlimit",
                                          disabled: ""
                                        },
                                        domProps: {
                                          value: _vm.historyData.creditLimit
                                        }
                                      })
                                    ])
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-4 col-form-label bold",
                                        attrs: { for: "Givepercent" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.GIVEN_PT")
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-8" }, [
                                      _c("input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "Givepercent",
                                          name: "Givepercent",
                                          placeholder: _vm.$t(
                                            "MANAGEMENT.GIVEN_PT"
                                          ),
                                          disabled: ""
                                        },
                                        domProps: {
                                          value: _vm.historyData.yourRate
                                        }
                                      })
                                    ])
                                  ])
                                ])
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-sm-6" },
                              [
                                _c("div", { staticClass: "mb-4" }, [
                                  _c("h4", { staticClass: "bold" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("MANAGEMENT.LIMIT_SETTING"))
                                    )
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c("div", {
                                        staticClass: "form-group row"
                                      })
                                    ])
                                  ])
                                ]),
                                _vm._l(_vm.limitListHistory, function(
                                  t,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "row" },
                                    [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c("label", {
                                              staticClass:
                                                "col-2 col-form-label bold"
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "col-10" },
                                              [
                                                _c("input", {
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: t.name + "history",
                                                    name: "limitHistory",
                                                    disabled: t.check
                                                      ? false
                                                      : true
                                                  },
                                                  domProps: { checked: t.check }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: t.name + "history"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(t.name) +
                                                        " " +
                                                        _vm._s(
                                                          "" +
                                                            _vm.$t(
                                                              "MANAGEMENT.MIN"
                                                            ) +
                                                            " = " +
                                                            t.min +
                                                            " , " +
                                                            _vm.$t(
                                                              "MANAGEMENT.MAX"
                                                            ) +
                                                            " = " +
                                                            t.max +
                                                            ""
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("i", {
                                                      class:
                                                        _vm.historyData.limit >=
                                                        t.value
                                                          ? "fa fa-check text-success"
                                                          : "fa fa-times text-danger"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        ])
                      ])
                    : _vm._e()
                ]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "form-group row" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }