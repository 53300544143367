





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import EditMemberComponent from "./EditMember.component";

@Component
export default class EditMember extends Mixins(EditMemberComponent) {}

