import { Component, Vue } from "vue-property-decorator";
import VueCookies from 'vue-cookies'
import { GET_PROFILE_MEMBER, EDIT_MEMBER, GET_ROYALTY_SETTING_BY_ID, GET_HISTORY_MEMBER, GET_HISTORY_MEMBER_BY_DATE } from "../../../../api/account"
import { FLOD_LIMIT_LIST, HOLDEM_LIMIT_LIST, SIXPLUS_HOLDEMLIMIT_LIST, PLO4_LIMIT_LIST, PLO5_LIMIT_LIST } from "../../Agent/Component/gameTypeLimit.component"
import vSelect from 'vue-select';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'
import moment from 'moment';

Vue.use(VModal, { componentName: 'modal' })
Vue.use(VueCookies)

import 'vue-select/dist/vue-select.css';

@Component({
    components: {
        vSelect
    },
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        date(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        sCurrency(value: any) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
})

default class EditMember extends Vue {
    $axios: any;

    private selected = "";
    private navShow = true
    private displayShow = true

    private role = ""
    private parentRoyaltySetting: any = {}
    private royaltySetting: any = []

    public updateDate = "";
    private profile: any = {}
    private historyData: any = {}
    private myRate = 0
    private userId = ""
    private cookieData: any = Vue.$cookies.get("login")
    private options: { text: string; value: string }[] = []
    

    private dataMember: any = {
        username: '',
        name: '',
        password: "",
        confirmPassword: "",
        email: '',
        phone: '',
        referrer: '',
        remark: '',
        suspend: false,
        lock: false,
        currency: "",
        creditLimit: 0,

        flodStatus:false,
        flodLimitArr: [],

        holdemStatus: false,
        holdemLimitArr: [],

        sixholdemStatus: false,
        sixholdemLimitArr: [],

        plo4Status: false,
        plo4LimitArr: [],

        plo5Status: false,
        plo5LimitArr: [],

        royaltySetting: [],
    }

    private allPercentageVal = {
        flodOurPercentage: 0,
        flodHoldPercentage: 0,

        holdemOurPercentage: 0,
        holdemHoldPercentage: 0,

        sixholdemOurPercentage: 0,
        sixholdemHoldPercentage: 0,

        plo4OurPercentage: 0,
        plo4HoldPercentage: 0,

        plo5OurPercentage: 0,
        plo5HoldPercentage: 0,
    }

    private flod_limit_list = FLOD_LIMIT_LIST
    private holdem_limit_list = HOLDEM_LIMIT_LIST
    private sixplus_holdemlimit_list = SIXPLUS_HOLDEMLIMIT_LIST
    private plo4_limit_list = PLO4_LIMIT_LIST
    private plo5_limit_list = PLO5_LIMIT_LIST

    private productPercentSelectionMaster = {
        allinorfold: [],
        holdem: [],
        sixplus: [],
        plo4: [],
        plo5: [],
    }

    private password = {
        default: false,
        confirm: false
    }

    private limitListHistory = [
        { name: 'Limit A', min: 40, max: 200, value: 1, check: false },
        { name: 'Limit B', min: 200, max: 1000, value: 2, check: false },
        { name: 'Limit C', min: 2000, max: 10000, value: 3, check: false },
        { name: 'Limit D', min: 10000, max: 50000, value: 4, check: false },
        { name: 'Limit E', min: 50000, max: 100000, value: 5, check: false }
    ]

    private flodpIndex = [ "flodOurPercentage", "flodHoldPercentage"]
    private holdempIndex = [ "holdemOurPercentage", "holdemHoldPercentage"]
    private sixholdempIndex = [ "sixholdemOurPercentage", "sixholdemHoldPercentage"]
    private plo4pIndex = [ "plo4OurPercentage", "plo4HoldPercentage"]
    private plo5pIndex = [ "plo5OurPercentage", "plo5HoldPercentage"]

    private historyLists = []
    private historyLoading = true;
    private model: Model;
    private numberArray: any = []
    private currencyArray: any = []
    constructor() {
        super();
        this.model = new Model();
    }

    async mounted() {
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        await this.getRoyaltySetting()
        this.userId = this.$route.query.id.toString()
        await this.getProfile(this.userId)
        await this.initData()
        this.setRate()
        this.addRouteName()
        loader.hide()
    }

    public async getRoyaltySetting(): Promise<void> {
        this.parentRoyaltySetting = await GET_ROYALTY_SETTING_BY_ID(this.cookieData.realUsername)
        this.parentRoyaltySetting = this.parentRoyaltySetting[0].royaltySetting.products
    }

    private async getProfile(username: string): Promise<void> {
        username = `${this.cookieData.realUsername}@${username}`;
        const result = await GET_PROFILE_MEMBER(username)
        this.role = this.cookieData.roles[0]
        this.profile = result[0]
        this.dataMember.username = this.profile.username
        this.dataMember.name = this.profile.name
        this.dataMember.lock = this.profile.lock
        this.dataMember.suspend = this.profile.suspend
        this.dataMember.email = this.profile.information.contact.email
        this.dataMember.phone = this.profile.information.contact.phone
        this.dataMember.referrer = this.profile.information.other.referer
        this.dataMember.remark = this.profile.information.other.remark
        this.dataMember.creditLimit = this.profile.creditLimit
        this.dataMember.currency = process.env.VUE_APP_CURRENCY_USE

        this.royaltySetting = this.profile.royaltySetting

        const rawData = await GET_HISTORY_MEMBER(username.toString())
        this.historyLists = rawData.data
    }

    public async initData(): Promise<void> {
        const currencyUse = process.env.VUE_APP_CURRENCY_USE
        this.currencyArray.push({ label: `${currencyUse}`, value: `${currencyUse}` });
        this.dataMember['parentName'] = this.cookieData.realUsername
        this.dataMember['parentId'] = this.cookieData.realUsername
    }

    public setRate(): void {
        if(this.royaltySetting === "" ){
            return
        }
        
        for (const [objName, productSetting] of Object.entries(this.royaltySetting.products)) {            
            const localValName = this.findLocalGameTypeVariableName(objName)
            if(!localValName){
                continue
            }
            this.setEachProductSetting(objName, localValName, productSetting)
        }
        return
    }

    private addRouteName(): void {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public setEachProductSetting(objName: string, localValName: any, productSetting: any): void {
        this.dataMember[localValName.status] = productSetting.enable
        this.dataMember[localValName.limit] = productSetting.limit
        this.allPercentageVal[localValName.percentSelection[0]] = {label:`${productSetting.rate}%`, value: productSetting.rate}
        this.allPercentageVal[localValName.percentSelection[1]] = {label:`${0}%`, value: 0}
    }

    public toggleShowPassword(name: string): void {
        if (name == 'default') {
            this.password.default = !this.password.default;
        }
        if (name == 'confirm') {
            this.password.confirm = !this.password.confirm;
        }
    }
    
    public findLocalGameTypeVariableName( objName: string): any {
        const localProductObj = { 
            
            allinorfold: { 
                status: "flodStatus", 
                ourPercent: "flodOurPercentage", 
                holdPercent: "flodHoldPercentage", 
                limit: "flodLimitArr",
                percentSelection: this.flodpIndex
            },
            holdem: { 
                status: "holdemStatus", 
                ourPercent: "holdemOurPercentage", 
                holdPercent: "holdemHoldPercentage", 
                limit: "holdemLimitArr",
                percentSelection: this.holdempIndex
            },
            sixplus: { 
                status: "sixholdemStatus", 
                ourPercent: "sixholdemOurPercentage", 
                holdPercent: "sixholdemHoldPercentage", 
                limit: "sixholdemLimitArr",
                percentSelection: this.sixholdempIndex
            },
            plo4: { 
                status: "plo4Status", 
                ourPercent: "plo4OurPercentage", 
                holdPercent: "plo4HoldPercentage", 
                limit: "plo4LimitArr",
                percentSelection: this.plo4pIndex
            },
            plo5: { 
                status: "plo5Status", 
                ourPercent: "plo5OurPercentage", 
                holdPercent: "plo5HoldPercentage", 
                limit: "plo5LimitArr",
                percentSelection: this.plo5pIndex
            },
        }

        for (const [key, value] of Object.entries(localProductObj)) {
            if(key == objName){
                return value
            }
        }
        return
    }

    public async getHistoryItem(date: string): Promise<void> {
        this.historyLoading = false
        const id = this.$route.query.id
        const rawData = await GET_HISTORY_MEMBER_BY_DATE(id.toString(), date)
        const historyData = rawData.data[0]
        this.historyData['username'] = historyData.username
        this.historyData['name'] = historyData.name
        this.historyData['lock'] = historyData.lock
        this.historyData['suspend'] = historyData.suspend
        this.historyData['email'] = historyData.information.contact.email
        this.historyData['phone'] = historyData.information.contact.phone
        this.historyData['referrer'] = historyData.information.other.referer
        this.historyData['remark'] = historyData.information.other.remark
        this.historyData['creditLimit'] = historyData.creditLimit
        this.historyData['currency'] = process.env.VUE_APP_CURRENCY_USE
        this.historyData['typeAgent'] = historyData.royaltySetting.typeAgent
        this.historyData['typeAgentAPI'] = historyData.royaltySetting.typeAgentAPI
        this.historyData['typeBusiness'] = historyData.royaltySetting.typeBusiness
        
        console.log("Refactor it")

        this.$modal.show('modalHistory');
        this.historyLoading = true
    }


    public onlyNumber(event: any): void {
        const keyCode = (event.keyCode ? event.keyCode : event.which);
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
            event.preventDefault();
        }
    }

    public clsAlphaNoOnly(event: any): boolean {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public clsAlphaTHNoOnly(event: any): boolean {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[ก-๏a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public formatNumber(value): string {
        return value.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    private hide() {
        this.$modal.hide('modalHistory');
    }

    public sendForm(event: any): void {
        event.preventDefault();
        this.sendCreateMember()
    }

    public async sendCreateMember(): Promise<void> {

        if (this.dataMember.password) {
            if (this.dataMember.password.length < 6) {
                this.$swal({
                    text: this.$t('MANAGEMENT.PASSWORD_CHECK').toString(),
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                });
                return;
            }
            if (this.dataMember.password != this.dataMember.confirmPassword) {
                this.$swal({
                    text: this.$t('MANAGEMENT.PASSWORD_BE_SAME').toString(),
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                });
                return;
            }
        }

        this.dataMember.flodStatus = this.convertToBoolean(this.dataMember.flodStatus)
        this.dataMember.holdemStatus = this.convertToBoolean(this.dataMember.holdemStatus)
        this.dataMember.sixholdemStatus = this.convertToBoolean(this.dataMember.sixholdemStatus)
        this.dataMember.plo4Status = this.convertToBoolean(this.dataMember.plo4Status)
        this.dataMember.plo5Status = this.convertToBoolean(this.dataMember.plo5Status)

        this.$swal({
            title: `${this.$t('MANAGEMENT.WANT_EDIT_DATA').toString()}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('CONFIRM').toString()}</span>`,
            cancelButtonColor: '#FF0000',
            cancelButtonText: `<span style='color: white;'>${this.$t('CANCEL').toString()}</span>`,
        }).then(async (result) => {
            if (!result.isConfirmed) {
                return
            }
            const resultEditMember = await EDIT_MEMBER(this.dataMember)
            if (resultEditMember.success) {
                this.$swal({
                    text: `${this.$t('MANAGEMENT.EDIT_MEMBER_SUCCESS').toString()}`,
                    icon: "success",
                    timer: 3000,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    onClose: () => {
                        window.location.replace('/agent/agent-member-list');
                    }
                });
            } else {
                this.$swal({
                    text: resultEditMember.error.message,
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                });
            }
            
        })

    }

    public convertToBoolean(myValue: any){
        if(typeof myValue === "string"){
            return (myValue === 'true')
        }
        else{
            return myValue
        }
    }


}
export default EditMember